import React from 'react';
import { AttunedLogo } from 'attuned-core/components';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: '1rem',
  textAlign: 'center',

  [theme.breakpoints.down('md')]: {
    paddingTop: '0.75rem',
  },
}));

const DefaultErrorView = ({
  error,
  message,
  children,
  showBackLink = true,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      sx={{
        height: '100vh',
        mx: 'auto',
        maxWidth: '800px',

        [theme.breakpoints.down('md')]: {
          maxWidth: '500px',
          px: '10px',
        },
      }}
    >
      <AttunedLogo />
      {
        children || (
          <>
            <StyledTypography
              variant="h5"
              color="primary"
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: '1rem',
                },
              }}
            >
              {error || t('error.default.title.page_not_available')}
            </StyledTypography>

            <StyledTypography
              variant="body1"
              color="primary"
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: '0.75rem',
                },
              }}
            >
              {message || t('error.default.message.broken_link')}
            </StyledTypography>

            {showBackLink && (
              <StyledTypography
                variant="body1"
                color="primary"
                sx={{
                  mt: 1,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '0.75rem',
                  },
                }}
              >
                <a href="/">{t('link_back_to_attuned')}</a>
              </StyledTypography>
            )}
          </>
        )
      }
    </Grid>
  );
};

export default DefaultErrorView;
