import React, { useCallback, useEffect, useState } from 'react';
import { BrandButton, BrandInput } from 'attuned-core/components';
import { validateEmail } from 'attuned-core/common';
import { LOGIN_ID_TYPES } from 'constants/index';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import { Link, useNavigate } from 'react-router-dom';
import LoginWrapper from 'components/LoginWrapper';
import Header from 'components/Header';
import { loginRequest } from 'slices/login';
import { useDispatch, useSelector } from 'react-redux';
import { sha224 } from 'js-sha256';
import { setNotification } from 'slices/notification';
import { buildNextUrl, useQuery } from 'utils';
import jwt_decode from 'jwt-decode';
import { trackMixPanelEvent } from 'services/thirdParty';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';

const DefaultSignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectUrl = useSelector((state) => state.login.redirectUrl);
  const locale = useSelector((state) => state.locale.locale);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRequirePassword, setIsRequirePassword] = useState(false);

  const { error, isRequesting, fetch } = useQuery({});

  const handleSignInWithEmail = useCallback(async () => {
    /*
      Make request with only email to determine if user will
      receive magic link or needs to input their password
    */
    try {
      const res = await fetch(() => loginRequest({
        username: email,
        next_url: redirectUrl,
      }));
      if (res.id === LOGIN_ID_TYPES.require_password || res.id === LOGIN_ID_TYPES.require_mfa) {
        setIsRequirePassword(true);
      }

      if (res.id === LOGIN_ID_TYPES.magic_link) {
        navigate('/magic-link', { state: { email } });
      }
    } catch (err) { } // eslint-disable-line no-empty
  }, [email, fetch, navigate, redirectUrl]);

  const handleLogin = async () => {
    if (isRequirePassword) {
      try {
        const res = await fetch(() => loginRequest({
          username: email,
          password: sha224(password),
          next_url: redirectUrl,
        }));
        let decoded = {};

        // Password is expired
        if (res?.access_token || res?.access_token_resp) {
          decoded = jwt_decode(res?.access_token || res?.access_token_resp);
          if ('expired' in decoded && decoded.expired === true) {
            navigate('/expired-password', { state: { currentPasswordHash: sha224(password) } });
            return;
          }

          // Login success
          trackMixPanelEvent(MIXPANEL_EVENTS.user_logs_in_to_account, true);
          await dispatch(setNotification({ message: 'Login success!', type: 'success' }));

          window.location.assign(buildNextUrl(
            redirectUrl,
            locale,
          ));
        }

        if (res?.requires_mfa) {
          navigate('/mfa', { state: { username: email, password: sha224(password) } });
        }
      } catch (err) { } // eslint-disable-line no-empty
    }
  };

  const handleButtonAction = (e) => {
    e.preventDefault();
    if (!isRequirePassword) {
      handleSignInWithEmail();
    }

    if (isRequirePassword) {
      handleLogin();
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(setNotification({
        message: error,
        type: 'error',
      }));
    }
  }, [error, dispatch]);
  return (
    <LoginWrapper>
      <Header
        title={t('default_signin_title')}
      />
      <BrandInput
        style={{ marginTop: '10px' }}
        label={t('email')}
        shouldValidateEmail
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Collapse in={isRequirePassword}>
        <BrandInput
          style={{ margin: '10px 0' }}
          label={t('password')}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Link to="/reset-password-request">
          {t('forgot_your_password')}
        </Link>
      </Collapse>
      <BrandButton
        type="submit"
        isRequesting={isRequesting}
        disabled={!validateEmail(email) || (isRequirePassword && !password) || isRequesting}
        onClick={handleButtonAction}
        style={{ marginTop: '10px' }}
        height="47px"
      >
        {isRequirePassword ? t('login') : t('sign_in_with_email')}
      </BrandButton>
    </LoginWrapper>
  );
};

export default DefaultSignIn;
