/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import DefaultSignIn from 'pages/Login/DefaultSignIn';
import MFA from 'pages/Login/MFA';
import ExpiredPassword from 'pages/Login/ExpiredPassword';
import ResetPassword from 'pages/Login/ResetPassword';
import SetPassword from 'pages/Login/SetPassword';
import MagicLink from 'pages/Login/MagicLink';
import LoginSuccess from 'pages/Login/LoginSuccess';
import ResetPasswordRequest from 'pages/Login/ResetPasswordRequest';
import MagicLinkAuthenticate from 'pages/Login/MagicLinkAuthenticate';
import Logout from 'pages/Login/Logout';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import NotificationBanner from 'components/NotificationBanner';
import LoadingPage from 'components/LoadingPage';
import { getQueryParam, getBrowserLanguage, hasCookiesDisabled } from 'attuned-core/common';
import { JA_LOCALE, EN_LOCALE } from 'attuned-core/constants';
import {
  setRedirectUrl,
} from 'slices/login';
import { fetchLocales, setLocale } from 'slices/locale';
import { useDispatch, useSelector } from 'react-redux';
import { REDIRECT_URL_PARAM } from 'constants/index';
import { setNotification } from 'slices/notification';
import { useTranslation } from 'react-i18next';
import { initThirdPartyServices } from 'services/thirdParty';
import QuickSignUp from 'pages/QuickSignUp';
import Error from 'pages/Error';
import DefaultErrorView from './components/ErrorBoundary/DefaultErrorView';

function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { isFetched: localeFilesFetched, locale } = useSelector((state) => state.locale);
  initThirdPartyServices();
  useEffect(() => {
    const queryLang = searchParams.get('lang');
    const defaultLang = getBrowserLanguage() === '日本語' ? JA_LOCALE : EN_LOCALE;
    dispatch(fetchLocales());
    dispatch(setLocale(queryLang || locale || defaultLang));
  }, []);

  useEffect(() => {
    const redirectUrl = getQueryParam(REDIRECT_URL_PARAM);

    dispatch(setRedirectUrl(redirectUrl));
  }, [dispatch]);

  useEffect(() => {
    if (hasCookiesDisabled()) {
      dispatch(setNotification({ message: t('message.cookies_disabled_warning'), type: 'error' }));
    }
  }, [dispatch, t]);

  if (!localeFilesFetched) {
    return (
      <LoadingPage />
    );
  }

  return (
    <ErrorBoundary>
      <NotificationBanner />
      <Routes>
        <Route path="*" element={<DefaultErrorView showBackLink />} />
        <Route exact path="/" element={<DefaultSignIn />} defaultRoute="/motivator" />
        <Route path="/mfa" element={<MFA />} />
        <Route path="/expired-password" element={<ExpiredPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="/magic-link" element={<MagicLink />} />
        <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
        <Route path="/login-success" element={<LoginSuccess />} />
        <Route path="/authenticate" element={<MagicLinkAuthenticate />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/quick-signup" element={<QuickSignUp />} />
        <Route path="/error" element={<Error />} />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
