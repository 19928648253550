/**
 * eslint-disable
 */
import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery, buildNextUrl } from 'utils';
import { getIdentity } from 'slices/identity';
import { AMP_URL, DASHBOARD_URL, MMP_URL } from 'constants/index';
import { LoadingSpinner } from 'attuned-core/components';

const LoginSuccess = () => {
  const { t } = useTranslation();
  const locale = useSelector((state) => state.locale.locale);

  /**
   * ATTUNED-3619
   * - respondent role only -> MMP
   * - current active role is owner, admin, manager -> dashboard (including those with amp roles)
   * - amp only -> AMP
   */

  const [isLoading, setIsLoading] = useState(true);
  const { fetch } = useQuery({});

  const fetchIdentity = useCallback(async () => {
    try {
      const res = await fetch(() => getIdentity());
      if (
        /* MMP-only user */
        res?.is_respondent && !res?.is_user && res?.respondent_data?.resp_ids_with_rp_access?.length
      ) {
        // redirect to MMP
        console.info('Redirecting to MMP...'); // eslint-disable-line no-console
        window.location.assign(buildNextUrl(MMP_URL, locale));
      } else if (
        /* AMP-only user */
        (res?.is_amp_user || res?.is_partner_user)
        && !res?.user_data?.current_active_account_id
      ) {
        // redirect to AMP
        console.info('Redirecting to AMP...'); // eslint-disable-line no-console
        window.location.assign(buildNextUrl(AMP_URL));
      } else if (
        /* Has user roles */
        res?.is_user
      ) {
        // redirect to dashboard
        console.info('Redirecting to dashboard...'); // eslint-disable-line no-console
        window.location.assign(buildNextUrl(DASHBOARD_URL));
      } else {
        setIsLoading(false);
      }
    } catch (err) { } // eslint-disable-line no-empty
  }, [fetch, locale]);

  useEffect(() => {
    fetchIdentity();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Typography
          variant="h5"
          sx={{ textAlign: 'center' }}
          dangerouslySetInnerHTML={{ __html: t('you_have_successfully_logged_in') }}
        />
      )}
    </Grid>
  );
};

export default LoginSuccess;
