import React, { useState } from 'react';
import LoginWrapper from 'components/LoginWrapper';
import Header from 'components/Header';
import {
  BrandInput, BrandButton, ErrorMessage, PasswordStrengthHelper,
} from 'attuned-core/components';
import { validatePassword } from 'attuned-core/common';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { updatePasswordRequest } from 'slices/login';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from 'slices/notification';
import { sha224 } from 'js-sha256';
import { buildNextUrl, useQuery } from 'utils';
import { trackMixPanelEvent } from 'services/thirdParty';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';

const ExpiredPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale.locale);
  const redirectUrl = useSelector((state) => state.login.redirectUrl);

  const { fetch, error: requestError } = useQuery({});

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError(t('the_two_passwords_dont_match'));
      return;
    }

    if (!validatePassword(newPassword)) {
      setError(t('password_invalid'));
      return;
    }

    try {
      await fetch(() => updatePasswordRequest({
        current_pwd: location.state.currentPasswordHash,
        new_pwd: sha224(newPassword),
      }));

      await dispatch(setNotification({ message: t('successfully_updated_password'), type: 'success' }));
      trackMixPanelEvent(MIXPANEL_EVENTS.user_logs_in_to_account, true);
      window.location.assign(buildNextUrl(redirectUrl, locale));
    } catch (err) { } // eslint-disable-line no-empty
  };

  return (
    <LoginWrapper>
      <Header
        title={t('expired_password_title')}
        description={t('expired_password_description')}
      />
      <BrandInput
        style={{
          marginTop: '20px',
        }}
        type="password"
        label={t('new_password')}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <BrandInput
        style={{
          marginTop: '20px',
        }}
        type="password"
        label={t('confirm_password')}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <PasswordStrengthHelper password={newPassword} />
      <BrandButton
        type="submit"
        disabled={!newPassword || !confirmPassword}
        sx={{ marginTop: '20px' }}
        onClick={handleSubmit}
        height="47px"
      >
        {t('save_changes')}
      </BrandButton>
      <ErrorMessage sx={{ marginTop: '10px' }}>
        {error || requestError}
      </ErrorMessage>
    </LoginWrapper>
  );
};

export default ExpiredPassword;
