/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useEffect, useMemo, useCallback, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid, Typography, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import Header from 'components/Header';
import {
  AttunedLogo, BrandButton, BrandCheckbox, BrandInput, ErrorMessage,
} from 'attuned-core/components';
import { useQuery } from 'utils';
import { hasCookiesDisabled } from 'attuned-core/common';
import { quickSignUp, validateQuickSignUpKey } from 'slices/quickSignUp';
import { useSelector } from 'react-redux';
import { JA_LOCALE } from 'attuned-core/constants';
import { SURVEY_URL } from 'constants/index';
import Footer from 'components/Footer';
import { trackMixPanelEvent } from 'services/thirdParty';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';

const StyledRoot = styled('form')``;

const StyledContainer = styled(Grid)(() => ({
  height: '90vh',
  width: '100vw',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  background: 'white',
  padding: '28px',
  borderRadius: '6px',
  boxShadow: '0px 4px 6px rgba(170, 170, 170, 0.25)',

  [theme.breakpoints.up('md')]: {
    minWidth: '400px',
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  maxWidth: '450px',

  [theme.breakpoints.down('sm')]: {
    minWidth: '95%',
  },

  [theme.breakpoints.down('md')]: {
    marginInline: '10px',
    minWidth: '45%',

    '.MuiButton-root': {
      fontSize: '0.75rem',
    },
  },
}));

const StyledBrandInput = styled(BrandInput)(({ theme }) => ({
  marginBottom: '1rem',

  [theme.breakpoints.down('md')]: {
    marginBottom: '0.5rem',

    '.BrandInput-controlLabel': {
      fontSize: '0.75rem !important', // TODO this should not need `important`
    },
  },
}));

function QuickWrapper({ children }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fetch } = useQuery({});
  const [isLoaded, setIsLoaded] = useState(false);

  const quickSignUpKey = useRef(window.location.hostname.split('.')[0]);

  useEffect(() => {
    (async () => {
      const { isValid } = await fetch(() => validateQuickSignUpKey({
        key: quickSignUpKey.current,
      }));

      if (!isValid) {
        trackMixPanelEvent(MIXPANEL_EVENTS.quick_sign_up_error_invalid_key, true);

        navigate('/error', {
          state: {
            error: t('quick_sign_up.error.invalid_key.title'),
            message: t('quick_sign_up.error.invalid_key.message'),
          },
        });
      }

      setIsLoaded(true);
    })();
  }, []);

  return (
    <StyledRoot>
      <StyledContainer container justifyContent="center" alignItems="center">
        <StyledGrid item>
          {isLoaded && (
            <>
              <Grid container direction="column" alignItems="center" sx={{ marginY: 1 }}>
                <a
                  target="_blank"
                  href="https://attuned.ai"
                  rel="noreferrer"
                >
                  <AttunedLogo
                    style={{
                      height: '60px',
                      margin: '0 20px 10px',
                    }}
                  />
                </a>
              </Grid>
              <StyledBox>
                {children}
              </StyledBox>

              <Footer />
            </>
          )}
        </StyledGrid>
      </StyledContainer>
    </StyledRoot>
  );
}

function QuickSignUp() {
  const { t } = useTranslation();
  const { locale } = useSelector((state) => state.locale);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isAgreeToTerm, setIsAgreeToTerm] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const {
    error: requestError,
    isRequesting,
    fetch,
  } = useQuery({});

  useEffect(() => {
    setIsFormDisabled(hasCookiesDisabled());
  }, []);

  const handleSignUp = useCallback(async (event) => {
    event.preventDefault();

    const payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      company_name: companyName,
      language_id: locale === JA_LOCALE ? '3' : '1',
    };

    try {
      const {
        self_motivator,
        respondent_survey_id,
      } = await fetch(() => quickSignUp(payload));

      trackMixPanelEvent(MIXPANEL_EVENTS.quick_sign_up_create_respondent, true);

      window.location.assign(`${SURVEY_URL}/${(
        self_motivator || respondent_survey_id
      )}?redirected_login_success=true&skip_terms=true`);
    } catch (err) { } /* eslint-disable-line no-empty */
  }, [email, fetch, firstName, lastName, locale]);

  const isFormCompleted = useMemo(
    () => firstName && lastName && email && companyName,
    [email, firstName, lastName, companyName],
  );

  return (
    <QuickWrapper>
      <Header
        title={t('quick_sign_up.header_title')}
        description={t('quick_sign_up.header_description')}
      />

      {/* Error message when cookies are disabled */}
      {hasCookiesDisabled() && (
        <Typography variant="subtitle1" component="p">
          {t('message.cookies_disabled_warning')}
        </Typography>
      )}

      {/* Names */}
      <StyledBrandInput
        label={t('first_name')}
        disabled={isFormDisabled}
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />

      <StyledBrandInput
        label={t('last_name')}
        disabled={isFormDisabled}
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />

      {/* Email */}
      <StyledBrandInput
        label={t('email')}
        disabled={isFormDisabled}
        type="email"
        shouldValidateEmail
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <StyledBrandInput
        label={t('sign_up.company_name')}
        disabled={isFormDisabled}
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
      />

      <BrandCheckbox
        disabled={isFormDisabled}
        label={(
          <Trans
            i18nKey="sign_up.agree_to_terms"
            components={{ a: <a target="_blank" /> }} // eslint-disable-line
          />
        )}
        value={isAgreeToTerm}
        onChange={() => setIsAgreeToTerm(!isAgreeToTerm)}
      />

      <BrandButton
        type="submit"
        disabled={!isAgreeToTerm || !isFormCompleted || isFormDisabled}
        isRequesting={isRequesting}
        sx={{ marginTop: '20px' }}
        height="47px"
        onClick={handleSignUp}
      >
        {t('quick_sign_up.action.start_assessment')}
      </BrandButton>

      <ErrorMessage sx={{ marginTop: '10px' }}>
        {requestError}
      </ErrorMessage>
    </QuickWrapper>
  );
}

export default QuickSignUp;
