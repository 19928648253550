import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const RequestCountdown = ({ startCountdown, setFinishedCountdown }) => {
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (countdown === 0) {
        setFinishedCountdown(false);
        clearInterval(countdownInterval);
        return;
      }
      setCountdown(countdown - 1);
    }, 1000);
    return () => clearInterval(countdownInterval);
  }, [countdown, setFinishedCountdown]);

  useEffect(() => {
    if (startCountdown) {
      setCountdown(120);
    }
  }, [startCountdown]);

  return (
    startCountdown && (
      <Grid container direction="column" alignItems="center" sx={{ marginTop: '7px', fontSize: '0.9rem' }}>
        {t('send_request_again_in_x_seconds', { seconds: `${countdown}` })}
      </Grid>
    )
  );
};

export default RequestCountdown;
