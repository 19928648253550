import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import i18n from 'i18next';
import { EN_LOCALE, JA_LOCALE } from 'attuned-core/constants';

/**
 * The order of the locales being loaded must be preserved
 * Error locales should be loaded before the project locale
 */
export const loadAdditionalTranslations = (resources) => Promise.all(
  resources.map(({ url, locale }) => axios.get(url, { headers: { 'X-lng': locale } })),
).then(
  (responses) => responses.map((res) => i18n.addResourceBundle(res.config.headers['X-lng'], 'translation', {
      ...res.data,
    }, /* deep */ true, /* overwrite */ true)),
);

export const fetchLocales = createAsyncThunk('FETCH_LOCALE', async () => {
  await loadAdditionalTranslations([
    {
      url: process?.env.REACT_APP_LOCALE_FILE_ERRORS_EN,
      locale: EN_LOCALE,
    },
    {
      url: process?.env.REACT_APP_LOCALE_FILE_ERRORS_JA,
      locale: JA_LOCALE,
    },
    {
      url: process?.env.REACT_APP_LOCALE_FILE_EN,
      locale: EN_LOCALE,
    },
    {
      url: process?.env.REACT_APP_LOCALE_FILE_JA,
      locale: JA_LOCALE,
    },
  ]);
});

export const locale = createSlice({
  name: 'Locale',
  initialState: {
    isFetched: false,
    locale: null,
  },
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
      // i18n locale is updated inside updateLocaleMiddleware in store.js
      // as it is as side effect and reducers should be "pure functions"
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLocales.fulfilled, (state) => {
        state.isFetched = true;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  getLocaleFile, getLocaleFileSuccess, getLocaleFileFailed, setLocale,
} = locale.actions;

export default locale.reducer;
