import React from 'react';
import DefaultErrorView from 'components/ErrorBoundary/DefaultErrorView';
import { useLocation } from 'react-router-dom';

function Error() {
  const { state } = useLocation();

  return (
    <DefaultErrorView
      showBackLink={false}
      error={state?.error}
      message={state?.message}
    />
  );
}

export default Error;
