import { styled } from '@mui/material/styles';
import { LIGHT_RED } from 'attuned-core/colors';

const RedLine = styled('hr')(() => ({
    width: '15%',
    margin: '1.05714286em 0',
    border: `1px solid ${LIGHT_RED}`,
  }));

  export default RedLine;
