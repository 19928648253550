import React from 'react';
import { setLocale } from 'slices/locale';
import { useSelector, useDispatch } from 'react-redux';
import { EN_LOCALE, JA_LOCALE } from 'attuned-core/constants';
import LanguageIcon from '@mui/icons-material/Language';
import { Button, Typography } from '@mui/material';

const LanguageSwitcher = () => {
    const dispatch = useDispatch();
    const { locale } = useSelector((state) => state.locale);
    const handleUpdateLanguage = () => {
        dispatch(setLocale(locale === EN_LOCALE ? JA_LOCALE : EN_LOCALE));
    };
    return (
      <Button onClick={handleUpdateLanguage} disableRipple variant="text">
        <LanguageIcon sx={{ fontSize: '1rem', marginRight: '5px' }} color="primary" />
        <Typography
          sx={{
            textDecoration: 'underline',
            fontSize: '0.8125rem', // 13px
            textTransform: 'none',
          }}
        >
          {locale === EN_LOCALE ? '日本語' : 'English'}
        </Typography>
      </Button>
    );
};

export default LanguageSwitcher;
