import { createSlice } from '@reduxjs/toolkit';
import { LIGHT_MODE } from 'attuned-core/constants';

export const initialState = {
  theme: LIGHT_MODE,
  fontSize: 'medium',
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleColorTheme: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.theme = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleColorTheme,
} = settings.actions;

export default settings.reducer;
