/* eslint-disable import/prefer-default-export */
import { attunedApi } from 'services';
import { createSlice } from '@reduxjs/toolkit';

export const loginRequest = async (payload) => {
  const response = await attunedApi.post('/v2/token', payload);
  return response.data;
};

export const updatePasswordRequest = async (payload) => {
  const response = await attunedApi.post('/v2/identities/me/password', payload);
  return response.data;
};

export const requestMagicLink = async (email) => {
  const response = await attunedApi.post('/token/respondent/request', { email });
  return response.data;
};

export const logout = async () => {
  const res = await attunedApi.delete('/v2/token');
  return res;
};

export const login = createSlice({
    name: 'Login',
    initialState: {
      user: null,
      redirectUrl: null,
    },
    reducers: {
      setRedirectUrl: (state, action) => {
        state.redirectUrl = action.payload;
      },
    },
});

export const {
  setRedirectUrl,
} = login.actions;

export default login.reducer;
