import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { loginRequest } from 'slices/login';
import Typography from '@mui/material/Typography';
import { LoadingSpinner } from 'attuned-core/components';
import { Grid } from '@mui/material';
import { buildNextUrl, useQuery } from 'utils';
import { useSelector } from 'react-redux';
import { trackMixPanelEvent } from 'services/thirdParty';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';

const MagicLinkAuthenticate = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const redirectUrl = useSelector((state) => state.login.redirectUrl);
  const locale = useSelector((state) => state.locale.locale);

  const { fetch, error } = useQuery({});

  useEffect(() => {
    console.error(error); /* eslint-disable-line no-console */
  }, [error]);

  useEffect(() => {
    const token = searchParams.get('token');
    (async () => {
      try {
        if (token) {
          await fetch(() => loginRequest({
            username: 'TOKEN',
            password: token,
          }));
          trackMixPanelEvent(MIXPANEL_EVENTS.user_logs_in_to_account, true);
          window.location.assign(buildNextUrl(redirectUrl, locale));
        }
      } catch (err) {
        navigate('/');
      }
    })();
  });

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100vh' }}
    >
      <Typography variant="h5" color="primary" style={{ marginBottom: '10px' }}>Authenticating</Typography>
      <LoadingSpinner />
    </Grid>
  );
};

export default MagicLinkAuthenticate;
