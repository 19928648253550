import React, { useState, useEffect } from 'react';
import { BrandInput, BrandButton } from 'attuned-core/components';
import { validateEmail } from 'attuned-core/common';
import LoginWrapper from 'components/LoginWrapper';
import Header from 'components/Header';
import { useLocation } from 'react-router-dom';
import { setNotification } from 'slices/notification';
import { updatePasswordRequest } from 'slices/login';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'utils';
import { useTranslation } from 'react-i18next';
import RequestCountdown from 'components/RequestCountdown';

const ResetPasswordRequest = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [hasRequested, setHasRequested] = useState(false);
  const [email, setEmail] = useState(location?.state?.email);
  const [error, setError] = useState(null);
  const redirectUrl = useSelector((state) => state.login.redirectUrl);
  const dispatch = useDispatch();

  const { fetch, error: requestError } = useQuery({});

  const handleSendPasswordReset = async (e) => {
    e.preventDefault();
    if (hasRequested) {
      setError(t('password_reset_link_already_sent'));
      return;
    }

    try {
      await fetch(() => updatePasswordRequest({ email, next_url: redirectUrl }));
      await dispatch(setNotification({ message: t('sent_password_reset_link'), type: 'success' }));
      setHasRequested(true);
    } catch (err) { } // eslint-disable-line no-empty
  };

  useEffect(() => {
    if (error || requestError) {
      dispatch(setNotification({
        message: error || requestError,
        type: 'error',
      }));
    }
  }, [error, requestError, dispatch]);
  return (
    <LoginWrapper>
      <Header
        title={t('reset_password_request_title')}
        description={t('reset_password_request_description')}
      />
      <BrandInput
        style={{ marginTop: '10px' }}
        label={t('email')}
        shouldValidateEmail
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <BrandButton
        type="submit"
        disabled={!email || !validateEmail(email) || hasRequested}
        sx={{ marginTop: '20px' }}
        onClick={handleSendPasswordReset}
        height="47px"
      >
        {t('send_me_password_reset_link')}
      </BrandButton>

      {hasRequested && (
        <RequestCountdown
          startCountdown={hasRequested}
          setFinishedCountdown={setHasRequested}
        />
      )}
    </LoginWrapper>
  );
};

export default ResetPasswordRequest;
