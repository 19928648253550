import React from 'react';
import { Typography } from '@mui/material';
import RedLine from 'components/RedLine';

const Header = ({ title, description }) => (
  <>
    <Typography variant="loginTitle" sx={{ fontWeight: 700, fontSize: '22px' }}>
      {title}
    </Typography>
    <Typography sx={{ marginTop: '10px', maxWidth: '450px' }}>
      {description}
    </Typography>
    <RedLine />
  </>
);

export default Header;
