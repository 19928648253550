import React, { useState, useEffect } from 'react';
import { BrandInput, BrandButton } from 'attuned-core/components';
import LoginWrapper from 'components/LoginWrapper';
import Header from 'components/Header';
import { useLocation } from 'react-router-dom';
import { setNotification } from 'slices/notification';
import { loginRequest } from 'slices/login';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'utils';
import { useTranslation } from 'react-i18next';
import RequestCountdown from 'components/RequestCountdown';

const MagicLink = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const redirectUrl = useSelector((state) => state.login.redirectUrl);
  const [email, setEmail] = useState(location?.state?.email);
  const [hasRequested, setHasRequested] = useState(true);
  const dispatch = useDispatch();

  const { fetch, error } = useQuery({});

  const handleResendMagicLink = async (e) => {
    e.preventDefault();
    try {
      await fetch(() => loginRequest({
        username: email,
        next_url: redirectUrl,
      }));
      await dispatch(setNotification({ message: t('resent_magic_link_to_your_email'), type: 'success' }));
      setHasRequested(true);
    } catch (err) { } // eslint-disable-line no-empty
  };

  useEffect(() => {
    if (error) {
      dispatch(setNotification({
        message: error,
        type: 'error',
      }));
    }
  }, [error, dispatch]);
  return (
    <LoginWrapper>
      <Header
        title={t('magic_link_title')}
        description={t('magic_link_description')}
      />
      <BrandInput
        disabled
        style={{ marginTop: '10px' }}
        label={t('email')}
        shouldValidateEmail
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <BrandButton
        type="submit"
        disabled={!email || hasRequested}
        sx={{ marginTop: '20px' }}
        onClick={handleResendMagicLink}
        height="47px"
      >
        {t('request_magic_link_again')}
      </BrandButton>
      {hasRequested && (
        <RequestCountdown
          startCountdown={hasRequested}
          setFinishedCountdown={setHasRequested}
        />
      )}
    </LoginWrapper>
  );
};

export default MagicLink;
