import React, { useState, useEffect } from 'react';
import LoginWrapper from 'components/LoginWrapper';
import Header from 'components/Header';
import {
  BrandInput, BrandButton, ErrorMessage, PasswordStrengthHelper,
} from 'attuned-core/components';
import { validatePassword, getQueryParam } from 'attuned-core/common';
import { updatePasswordRequest } from 'slices/login';
import { useDispatch, useSelector } from 'react-redux';
import { sha224 } from 'js-sha256';
import { useTranslation } from 'react-i18next';
import { setNotification } from 'slices/notification';
import { useQuery } from 'utils';
import { createSearchParams, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fieldError, setFieldError] = useState(null);
  const redirectUrl = useSelector((state) => state.login.redirectUrl);

  const { error, isRequesting, fetch } = useQuery({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFieldError(null);
    if (newPassword !== confirmPassword) {
      setFieldError(t('the_two_passwords_dont_match'));
      return;
    }

    if (!validatePassword(newPassword)) {
      setFieldError(t('password_invalid'));
      return;
    }

    try {
      await fetch(() => updatePasswordRequest({
        new_pwd: sha224(newPassword),
        token: getQueryParam('token'),
      }));

      await dispatch(setNotification({ message: t('successfully_updated_password'), type: 'success' }));
      navigate({
        pathname: '/',
        search: createSearchParams({ next_url: redirectUrl }).toString(),
      });
    } catch (err) { } // eslint-disable-line no-empty
  };

  useEffect(() => {
    if (error) {
      dispatch(setNotification({ message: error, type: 'error' }));
    }
  }, [error, dispatch, navigate]);

  return (
    <LoginWrapper>
      <Header
        title={t('reset_password_title')}
        description={t('reset_password_description')}
      />
      <BrandInput
        style={{
          marginTop: '20px',
        }}
        type="password"
        label={t('new_password')}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <BrandInput
        style={{
          marginTop: '20px',
        }}
        type="password"
        label={t('confirm_password')}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <PasswordStrengthHelper password={newPassword} />
      <BrandButton
        type="submit"
        disabled={!newPassword || !confirmPassword || isRequesting}
        sx={{ marginTop: '20px' }}
        onClick={handleSubmit}
        height="47px"
      >
        {t('save_password')}
      </BrandButton>
      <ErrorMessage sx={{ marginTop: '10px' }}>
        {fieldError}
      </ErrorMessage>
    </LoginWrapper>
  );
};

export default ResetPassword;
