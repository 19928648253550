import React, { useEffect } from 'react';
import { logout } from 'slices/login';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

const Logout = () => {
    const navigate = useNavigate();
    useEffect(() => {
    (async () => {
        try {
            await logout();
        } finally {
            navigate('/');
        }
    })();
    }, [navigate]);

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100vh' }}
      >
        <Typography variant="h5" color="primary" style={{ marginBottom: '10px' }}>Logging out...</Typography>
      </Grid>
    );
};

export default Logout;
