import React, { useState } from 'react';
import LoginWrapper from 'components/LoginWrapper';
import Header from 'components/Header';
import {
  BrandInput, BrandButton, ErrorMessage, BrandCheckbox, PasswordStrengthHelper,
} from 'attuned-core/components';
import { validatePassword, getQueryParam } from 'attuned-core/common';
import { Typography, Stack } from '@mui/material';

import { updatePasswordRequest } from 'slices/login';
import { useQuery } from 'utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from 'slices/notification';
import { sha224 } from 'js-sha256';
import { useNavigate } from 'react-router-dom';

const SetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [formError, setError] = useState(null);

  const { error, isRequesting, fetch } = useQuery({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError(t('the_two_passwords_dont_match'));
      return;
    }

    if (!validatePassword(newPassword)) {
      setError(t('password_invalid'));
      return;
    }

    if (!isAgreed) {
      setError(t('must_check_privacy_policy'));
      return;
    }

    try {
      await fetch(() => updatePasswordRequest({
        token: getQueryParam('token'),
        new_pwd: sha224(newPassword),
      }));
      await dispatch(setNotification({ message: t('successfully_updated_password'), type: 'success' }));
      navigate('/');
    } catch (err) { } // eslint-disable-line no-empty
  };

  return (
    <LoginWrapper showSignUpLink={false}>
      <Header
        title={t('set_password_title')}
        description={t('set_password_description')}
      />
      <BrandInput
        style={{
          marginTop: '20px',
        }}
        type="password"
        label={t('new_password')}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <BrandInput
        style={{
          marginTop: '20px',
        }}
        type="password"
        label={t('confirm_password')}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <PasswordStrengthHelper password={newPassword} />

      <Stack direction="row" alignItems="center" sx={{ marginTop: '10px' }}>
        <BrandCheckbox checked={isAgreed} onChange={() => setIsAgreed(!isAgreed)} />
        <Typography
          variant="caption"
          dangerouslySetInnerHTML={{
            __html: t('agree_with_privacy_and_terms'),
          }}
        />
      </Stack>
      <BrandButton
        type="submit"
        disabled={!newPassword || !confirmPassword || !isAgreed || isRequesting}
        sx={{ marginTop: '20px' }}
        onClick={handleSubmit}
        height="47px"
      >
        {t('create_account')}
      </BrandButton>
      <ErrorMessage sx={{ marginTop: '10px' }}>
        {formError || error}
      </ErrorMessage>
    </LoginWrapper>
  );
};

export default SetPassword;
