import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {}, // language to use, more information here:
    // https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually:
    // https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    lng: 'en',
    fallbackLng: 'en',
    saveMissing: true,
    missingKeyHandler(lngs, ns, key, fallbackValue) {
      if (process.env.NODE_ENV === 'production') {
        const error = new Error(`Missing translation: ${fallbackValue}`);
        datadogRum.addError(error);
      }
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
