/* eslint-disable import/prefer-default-export */
export const DASHBOARD_URL = `${process.env.REACT_APP_DASHBOARD_URL}`;
export const SURVEY_URL = `${process.env.REACT_APP_SURVEY_URL}`;
export const AMP_URL = `${process.env.REACT_APP_AMP_URL}`;
export const MMP_URL = `${process.env.REACT_APP_MMP_URL}`;

export const LOGIN_ID_TYPES = {
    require_password: 1,
    magic_link: 2,
    require_mfa: 3,
};

export const REDIRECT_URL_PARAM = 'next_url';

export const REDIRECTED_LOGIN_SUCCESS = 'redirected_login_success';
