import React from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/LanguageSwitcher';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      sx={{ marginTop: '.625rem', paddingInline: '28px' }}
    >
      <Typography
        dangerouslySetInnerHTML={{ __html: t('privacy') }}
        sx={{
          fontSize: '0.8125rem',
          '> a': {
            textDecoration: 'none',
            color: '#33373E',
          },
        }}
      />

      <Typography
        dangerouslySetInnerHTML={{ __html: t('terms') }}
        sx={{
          fontSize: '0.8125rem',
          marginLeft: '20px',
          '> a': {
            textDecoration: 'none',
            color: '#33373E',
          },
        }}
      />

      <Box sx={{ ml: 'auto' }}>
        <LanguageSwitcher />
      </Box>
    </Grid>
  );
};

export default Footer;
