import { ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { generateTheme, attunedDarkTheme, attunedLightTheme } from 'attuned-core/themes';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { LIGHT_MODE } from 'attuned-core/constants';

const LoginDarkTheme = {
};

const LoginLightTheme = {
};

const overrides = {
  MuiOutlinedInput: {
    root: {
      input: {
        fontSize: '16px',
        padding: '12px 16px',
        fontWeight: 'normal',
      },
    },
  },
  MuiInputLabel: {
    root: {
      fontSize: '16px !important',
    },
  },
};

const getAppThemes = (settings) => generateTheme(
  settings.theme === LIGHT_MODE
    ? { ...attunedLightTheme, ...LoginLightTheme }
    : { ...attunedDarkTheme, ...LoginDarkTheme },
  'medium',
  overrides,
);

const AppThemeProvider = ({ children }) => {
  const settings = useSelector((state) => state.settings);
  const appThemes = useMemo(() => getAppThemes(settings), [settings]);
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={appThemes}>
        <ThemeProvider theme={appThemes}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppThemeProvider;
