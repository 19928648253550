/* eslint-disable import/prefer-default-export */
import { attunedApi } from 'services';

export async function quickSignUp(payload) {
  const { data } = await attunedApi.post('/v2/admin/respondents/candidates', payload);

  return data;
}

export async function validateQuickSignUpKey({ key }) {
  try {
    const { status } = await attunedApi.head(`/v2/quick_signup_links/${key}`);

    return { isValid: status === 200 };
  } catch (err) {
    return { isValid: false };
  }
}
