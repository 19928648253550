import { datadogRum } from '@datadog/browser-rum';
import mixpanel from 'mixpanel-browser';
import { getEnvironment } from 'attuned-core/common';

export function setupRUM() {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATA_DOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATA_DOG_SITE,
    service: process.env.REACT_APP_DATA_DOG_SERVICE_NAME,
    env: getEnvironment(),
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    replaySampleRate: 20,
    premiumSampleRate: 20,
    trackLongTasks: true,
    trackResources: true,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

export const initThirdPartyServices = () => {
  /* Initialize Mixpanel for tracking user interactions */
  window.ATTUNED_USE_PROXIES = process.env.ATTUNED_USE_PROXIES;
  mixpanel.init(
    process.env.REACT_APP_MIXPANEL_TOKEN_FRONTEND,
    window.ATTUNED_USE_PROXIES && window.ATTUNED_USE_PROXIES === '1' ? { api_host: '/mixpanel' } : {},
  );
  if (getEnvironment() !== 'dev') {
    setupRUM();
  }
};

export const trackMixPanelEvent = (eventName, immediate = false) => {
  try {
    mixpanel.track(eventName, {}, { send_immediately: immediate });
  } catch (err) {
    console.warn(`Failed to track event ${eventName}`, err); // eslint-disable-line no-console
  }
};
