/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import axios from 'axios';

const attunedApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export { attunedApi };
