/* eslint-disable import/prefer-default-export */
import {
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { REDIRECTED_LOGIN_SUCCESS } from 'constants/index';

export const getErrorMessage = (err, t) => {
  if (err?.response?.data?.error) {
    const {
      ...rest
    } = err.response.data;
    return t(err.response.data.error, rest); // Use error code to translate error from lokalise
  }

  return 'Something went wrong';
};

export const getErrorCode = (err) => err?.response?.data?.error;

export const useQuery = ({ request, dependencies, debounceTime }) => {
  /*
        Use this custom hook for api requests if you need to access particular
        attributes during the request (errors, requesting, data)

        For api requests when a component first loads, use it like so:
         ```
            const fetchMotivatorPercentiles = async () => {
                if (respondent?.account_id) {
                    const percentiles = await getRespondentMotivationPercentiles({
                        account_id: respondent.account_id,
                        respondent_id: respondent.id
                    });
                    setMotivationPercentiles(percentiles);
                }
            };

        const { data, isRequesting, error, fetch } = useQuery({
            request: fetchMotivatorPercentiles,
            dependencies: [respondent]
        });

        ```

        For api requests that occur inside of a handler, we use the fetch function:
        ```
            const { fetch, error, data, isRequesting } = useQuery({});

            try {
              const handleGetStuff = async () => {
                const res = await fetch(getStuff);
                if (res) { }
              };
            } catch (err) {} // eslint-disable-line no-empty
        ```

        You can provide a debounceTime argument to debounce your requests

    */
  const [error, setError] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [data, setData] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);

  const { t } = useTranslation();

  const setRequestAttributes = (d) => {
    setData(d);
    setIsRequesting(false);
    setError(null);
    setErrorCode(null);
  };

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      if (request) {
        try {
          setIsRequesting(true);
          if (debounceTime) {
            debounce(async () => {
              const res = await request();
              if (!isCancelled) {
                setRequestAttributes(res);
              }
            }, debounceTime)();
          } else {
            const res = await request();
            if (!isCancelled) {
              setRequestAttributes(res);
            }
          }
        } catch (err) {
          if (!isCancelled) {
            setIsRequesting(false);
            setErrorCode(getErrorCode(err));
            setError(getErrorMessage(err, t));
            throw err;
          }
        }
      }
    })();
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  const fetch = async (handlerRequest) => {
    try {
      setIsRequesting(true);
      setError(null);
      setErrorCode(null);
      const res = await handlerRequest();
      setData(res);
      setIsRequesting(false);
      return res;
    } catch (err) {
      setIsRequesting(false);
      setErrorCode(getErrorCode(err));
      setError(getErrorMessage(err, t));
      throw err;
    }
  };

  return {
    data, error, isRequesting, fetch, errorCode,
  };
};

export const buildNextUrl = (redirectUrl, locale) => {
  if (!redirectUrl) return '/login-success';

  const parsedUrl = new URL(redirectUrl);
  const urlSearchParam = new URLSearchParams(parsedUrl.search);

  urlSearchParam.append(REDIRECTED_LOGIN_SUCCESS, true);

  if (locale) {
    urlSearchParam.append('lang', locale);
  }

  return `${parsedUrl.origin}${parsedUrl.pathname}?${urlSearchParam.toString()}${parsedUrl.hash}`;
};
