import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Footer from 'components/Footer';
import { AttunedLogo } from 'attuned-core/components';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled(Grid)(() => ({
  height: '90vh',
  width: '100vw',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  background: 'white',
  padding: '28px',
  borderRadius: '6px',
  boxShadow: '0px 4px 6px rgba(170, 170, 170, 0.25)',
  [theme.breakpoints.up('md')]: {
    width: '400px',
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  maxWidth: '450px',
  [theme.breakpoints.down('sm')]: {
    minWidth: '95%',
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '45%',
  },
}));

const Root = styled('form')``;

const LoginWrapper = ({ children, showSignUpLink = true }) => {
  const { t } = useTranslation();
  return (
    <Root>
      <StyledContainer container justifyContent="center" alignItems="center">
        <StyledGrid container alignItems="center" flexDirection="column">
          <Grid container direction="column" alignItems="center" sx={{ margin: '16px 0' }}>
            <a
              target="_blank"
              href="https://attuned.ai"
              rel="noreferrer"
            >
              <AttunedLogo style={{ height: '60px', margin: '0 20px 10px' }} />
            </a>
            {showSignUpLink && (
              <Typography
                dangerouslySetInnerHTML={{ __html: t('new_to_attuned_get_started') }}
                sx={{
                  fontSize: '15px !important',
                  textAlign: 'center',
                  '> a': {
                    textDecoration: 'none',
                  },
                }}
              />
            )}
          </Grid>
          <StyledBox>
            {children}
          </StyledBox>
          <Footer />
        </StyledGrid>
      </StyledContainer>
    </Root>
  );
};

export default LoginWrapper;
