import React from 'react';
import { LoadingSpinner } from 'attuned-core/components';
import { Grid } from '@mui/material';

const LoadingPage = () => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    style={{ height: '100vh' }}
  >
    <LoadingSpinner />
  </Grid>
);

export default LoadingPage;
