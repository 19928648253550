import { createSlice } from '@reduxjs/toolkit';

export const notification = createSlice({
  name: 'Notification',
  initialState: {
    current: null,
  },
  reducers: {
    setNotification: (state, action) => {
      /* ex: {
          message: 'Notification message',
          type: 'notificationType' (error, warning, success, info)
      */
      state.current = action.payload;
    },
    dismissNotification: (state) => {
      state.current = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNotification, dismissNotification } = notification.actions;

export default notification.reducer;
