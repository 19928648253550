/* eslint-disable import/no-cycle */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import logger from 'redux-logger';
import i18n from 'i18next';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  createTransform,
} from 'redux-persist';
import settings from 'slices/settings';
import locale from 'slices/locale';
import notification from 'slices/notification';
import login from 'slices/login';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// Initialize date locales from dayjs
require('dayjs/locale/ja');

dayjs.extend(localizedFormat);

/*
  Transforms locale store object to retain only locale
  work around for redux-persist's broken nested blacklist/whitelist
  https://github.com/rt2zz/redux-persist/issues/1110
*/
const TransformLocale = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => ({ locale: inboundState.locale }),
  // transform state being rehydrated
  (outboundState) => ({ locale: outboundState.locale }),
  // define which reducers this transform gets called for.
  { whitelist: ['locale'] },
);

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  transforms: [TransformLocale],
  blacklist: ['notification', 'login'],
};

const combinedReducer = combineReducers({
  settings,
  locale,
  notification,
  login,
});

const rootReducer = (state, action) => {
  const newState = { ...state };
  return combinedReducer(newState, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Updating i18n and Day.js locale here instead of locale reducer since it's side effect
const updateLocaleMiddleware = () => (next) => (action) => {
  if (action.type === 'Locale/setLocale') {
    i18n.changeLanguage(action.payload);
    dayjs.locale(action.payload);
    return next(action);
  }
  return next(action);
};

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
    .concat(logger)
    .concat(updateLocaleMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});
