import React, { useEffect } from 'react';
import BrandAlert from 'attuned-core/components/BrandAlert';
import { Snackbar } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { dismissNotification } from 'slices/notification';

const NotificationBanner = () => {
    const notification = useSelector((state) => state.notification.current);
    const dispatch = useDispatch();

    useEffect(() => {
      const dismissNotificationTimeout = setTimeout(() => {
        if (!notification) return;

        dispatch(dismissNotification());
      }, 5000);

      return () => {
        clearTimeout(dismissNotificationTimeout);
      };
    }, [notification, dispatch]);

    return (
      <Snackbar
        open={notification !== null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {notification
            && (
            <BrandAlert
              message={notification?.message}
              content={notification?.content}
              type={notification?.type}
              onClose={() => dispatch(dismissNotification())}
              icon={notification?.icon}
            />
)}
      </Snackbar>
    );
};

export default NotificationBanner;
