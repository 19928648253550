/* eslint-disable no-shadow */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { MFAInput, BrandButton, ErrorMessage } from 'attuned-core/components';
import {
  Grid, Typography, Stack, Divider,
} from '@mui/material';
import LoginWrapper from 'components/LoginWrapper';
import Header from 'components/Header';
import { setNotification } from 'slices/notification';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest } from 'slices/login';
import { useQuery, buildNextUrl } from 'utils';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { trackMixPanelEvent } from 'services/thirdParty';
import { MIXPANEL_EVENTS } from 'constants/mixpanel';
import RequestCountdown from 'components/RequestCountdown';

const REQUIRED_INPUTS = 6;

const MFA = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [passcode, setPasscode] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasRequested, setHasRequested] = useState(true);
  const redirectUrl = useSelector((state) => state.login.redirectUrl);
  const locale = useSelector((state) => state.locale.locale);
  const dispatch = useDispatch();
  const inputRef = useRef([]);
  inputRef.current = Array(REQUIRED_INPUTS).fill(0).map(
    (ref, index) => inputRef.current[index] = React.createRef(),
  );

  const {
    fetch, error, errorCode, isRequesting,
  } = useQuery({});

  useEffect(() => {
    if (errorCode === 'WRONG_EMAIL_OR_PASSWORD' && error) {
      navigate('/', { state: { error, errorCode, username: location.state?.username } });
    }
  }, [errorCode, error, location.state?.username]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const handleChange = useCallback((e, idx) => {
    const newPasscode = [...passcode];
    newPasscode[idx] = e.target.value.length === 1 ? e.target.value : e.target.value[0];
    setPasscode(newPasscode);
    console.log('idx', idx); /* eslint-disable-line no-console */
    if (idx < REQUIRED_INPUTS - 1) {
      inputRef.current[idx + 1].current.focus();
    }
  }, [passcode]);

  const handleBack = useCallback((e, idx) => {
    if (e.key === 'Backspace') {
      const newPasscode = [...passcode];
      if (idx === REQUIRED_INPUTS - 1 && newPasscode[idx]) {
        newPasscode[idx] = null;
        setPasscode(newPasscode);
        e.preventDefault();
        return;
      }
      if (idx > 0) {
        newPasscode[idx - 1] = null;
        setPasscode(newPasscode);
        inputRef.current[idx - 1].current.focus();
      }
    }
  }, [passcode]);

  const handleResendPasscode = useCallback(async () => {
    try {
      const res = await fetch(() => loginRequest({
        username: location.state.username,
        password: location.state.password,
      }));
      if (res) {
        await dispatch(setNotification({ message: t('resent_passcode_to_your_email'), type: 'success' }));
        setPasscode([]);
        inputRef.current[0].current.focus();
        setHasRequested(true);
      }
    } catch (err) { } // eslint-disable-line no-empty
  }, [dispatch, fetch, location.state, t]);

  const handleSubmitPasscode = debounce(async ({ username, passwordHash, passcode }) => {
    try {
      const res = await fetch(() => loginRequest({
        username,
        password: passwordHash,
        mfa_code: passcode.join(''),
      }));

      let decoded = {};

      // Password is expired
      if (res?.access_token || res?.access_token_resp) {
        decoded = jwt_decode(res?.access_token || res?.access_token_resp);
        if ('expired' in decoded && decoded.expired === true) {
          navigate('/expired-password', { state: { currentPasswordHash: passwordHash } });
          return;
        }
      }

      // Login success
      if (res?.access_token || res?.access_token_resp) {
        setIsSuccess(true);
        trackMixPanelEvent(MIXPANEL_EVENTS.user_logs_in_to_account, true);
        await dispatch(setNotification({ message: t('successfully_logged_in'), type: 'success' }));
        window.location.assign(buildNextUrl(redirectUrl, locale));
      }
    } catch (err) { } // eslint-disable-line no-empty
  }, 200);

  const handlePaste = useCallback((e) => {
    const data = e.clipboardData.getData('text/plain');
    setPasscode(data.split(''));
    inputRef.current[REQUIRED_INPUTS - 1].current.focus();
  }, []);

  useEffect(() => {
    console.debug(passcode); /* eslint-disable-line no-console */
    if (passcode.filter((d) => !!d).length === REQUIRED_INPUTS
      && inputRef.current[5].current === document.activeElement) {
      handleSubmitPasscode({
        username: location.state?.username,
        passwordHash: location.state?.password,
        passcode,
      });
    }
  }, [passcode.join('')]); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    inputRef.current[0].current.focus();
  }, []);

  return (
    <LoginWrapper>
      <Header
        title={t('mfa_title')}
        description={t('mfa_description')}
      />
      <Typography
        sx={{ margin: '10px 0' }}
      >
        {t('enter_passcode')}
      </Typography>
      <Grid container spacing={1} justifyContent="space-between">
        {Array(REQUIRED_INPUTS).fill(0).map((_, idx) => (
          <Grid item key={idx}>
            <MFAInput
              onPaste={handlePaste}
              onKeyDown={(e) => handleBack(e, idx)}
              ref={inputRef.current[idx]}
              success={isSuccess}
              value={passcode[idx] || ''}
              onChange={(e) => handleChange(e, idx)}
              tabIndex={idx}
            />
          </Grid>
        ))}
      </Grid>
      <ErrorMessage sx={{ marginY: '20px' }}>
        {error}
      </ErrorMessage>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <ContentCopyIcon sx={{ fontSize: '1rem', marginRight: '5px' }} color="primary" />
        <Typography variant="caption" align="center">
          {t('you_can_copy_paste_the_pin_code_here')}
        </Typography>
      </Stack>
      <Divider sx={{ marginY: '20px' }} />
      <BrandButton
        variant="outlined"
        isRequesting={isRequesting}
        onClick={handleResendPasscode}
        style={{ marginTop: '20px' }}
        height="47px"
        disabled={hasRequested}
      >
        {t('resend_passcode')}
      </BrandButton>
      {hasRequested && (
        <RequestCountdown
          startCountdown={hasRequested}
          setFinishedCountdown={setHasRequested}
        />
      )}
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '100%',
          marginTop: '15px',
          '> a': {
            textDecoration: 'none',
            fontSize: '13px',
          },
        }}
      >
        <a href="/">{t('back_to_login_screen')}</a>
      </Grid>
    </LoginWrapper>
  );
};

export default MFA;
